<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark v-bind="attrs" v-on="on">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-filter </v-icon>
          </template>
          <span>Filtrer les alertes</span>
        </v-tooltip>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("filteralerts") | capitalize }}</span>
        <v-spacer />
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <span class="font-weight-medium">{{ options.key.text }}</span>
              <v-autocomplete
                :items="options.key.items"
                v-model="filters.key"
                deletable-chips
                clearable
                multiple
                outlined
                @click:clear="onKeyClear"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index < 3"
                    close
                    @click:close="filters.key.shift()"
                  >
                    <span>{{ item }} {{ index }}</span>
                  </v-chip>
                  <span v-if="index === 3">
                    (+{{ filters.key.length - 1 }}
                    {{ $tc("other", filters.key.length - 1) }})
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <span class="font-weight-medium">{{
                options.triggered_value.text
              }}</span>
              <FilterNumber
                filter_key="triggered_value"
                @change="onFilterNumberChange"
              />
            </v-col>
            <v-col cols="12">
              <span class="font-weight-medium">{{
                options.triggered_financial_risk.text
              }}</span>
              <FilterNumber
                filter_key="triggered_financial_risk"
                @change="onFilterNumberChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="undo" text> {{ $t("reset") }} </v-btn>
        <v-btn @click="search" dark> {{ $t("filter") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    reset: false,
  }),
  props: {
    filters: {
      type: Object,
    },
    options: {
      type: Object,
    },
  },
  components: {
    FilterNumber: () => import("@/components/Alerts/Filters/FilterNumber"),
  },
  methods: {
    onKeyClear() {
      this.filters.key = undefined;
      this.$emit("change");
    },
    onFilterNumberChange(key, val) {
      this.filters[key] = val;
    },
    undo() {
      this.dialog = false;
      this.$emit("reset");
    },
    search() {
      this.dialog = false;
      this.$emit("change");
    },
  },
};
</script>
